@import "https://fonts.googleapis.com/css?family=Product+Sans:300,400,500";
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400&display=swap");
/* @import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons"; */

@import "primeng/resources/themes/arya-green/theme.css";
/*@import "assets/themePrimeNgAPG.css";*/
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Quicksand",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  /* font-family: "Poppins", 'Quicksand', sans-serif; */
  /* font-family: 'Quicksand', sans-serif; */
  /* font-family: "Product Sans", "Helvetica Neue", sans-serif; */
  /* font-family: Roboto, "Helvetica Neue", sans-serif; */
  /* overflow-anchor: auto !important; */
}

html,
body {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-weight: 300;
}
body {
  margin: 0;
  background-color: #202020;
  color: #cacaca;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0 10px 0 10px;
  font-weight: 200;
}

/* Scrollbar */

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #303030 #20202000;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #20202000;
}

*::-webkit-scrollbar-thumb {
  background-color: #303030;
  border-radius: 20px;
  padding: 3px;
  /* border: 3px solid #202020; */
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
.flex-item {
  flex: 0 0 auto;
  margin: 0;
}

.flex-container-simple {
  display: flex;
}
.flex-item-full-width {
  flex: 1 1 100%;
  margin: 0;
}

.position-child-bottom-right {
  position: relative;
}
.position-child-bottom-right > * {
  position: absolute;
  bottom: 0;
  right: 0;
}

.mat-tooltip {
  white-space: pre-line;
}

.mat-button-wrapper {
  font-weight: 200;
}

.button-icon-text {
  margin-left: 5px;
  font-size: smaller;
  vertical-align: baseline !important;
}

button {
  vertical-align: baseline !important;
}

.row {
  display: flex;
}
.fill-space {
  /* This fills the remaining space, by using flexbox. */
  /* flex: 1 1 auto; */
  flex-grow: 1;
}

.blur-dialog-backdrop {
  /* background-color: #202020; */
  backdrop-filter: blur(1px);
}

.blur-dialog-backdrop-hard {
  /* background-color: #202020; */
  backdrop-filter: blur(5px);
}

.background {
  /* background-image: url("assets/images/AventiBakgrunn.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: absolute;
  background-size: 100%; */
  position: absolute;
  max-height: 50%;
  max-width: 150%;
  right: 0;
  bottom: 0;
  /* top: 0; */
  /* left: 0; */
  z-index: 0;
}

.mat-expansion-panel-body {
  background-color: #333;
}

.mat-snack-bar-container {
  background-color: #666;
  color: #eee;
  font-size: smaller;
}

.center-on-screen {
  position: absolute;
  /* margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.top-center {
  position: fixed;
  top: 7px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  z-index: 10;
}

.header-icon {
  padding: 8px;
}

.header-logo {
  background-image: url("assets/aventi_logo.png");
  background-size: cover;
  border-radius: 0 !important;
}

.header-icon-small {
  font-size: 12px;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
  margin-left: -3px;
  margin-right: -3px;
}

.strikethrough {
  text-decoration: line-through;
}

.old {
  color: #d32f2f;
}

.top-header-icon-text {
  vertical-align: top;
  padding-left: 5px;
}

.border {
  border: 1px solid #666;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}

.child-margin > * {
  margin: 10px;
}

.maxwidth {
  width: 100%;
  max-width: 600px;
}

.avatar {
  vertical-align: middle;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

mat-sidenav-container {
  height: 100%;
  width: 100%;
}
mat-sidenav-content {
  height: 100%;
  background-color: #202020;
}

mat-sidenav {
  height: 100%;
  width: 150px;
  /* transition: width 50ms; */
}

.mat-drawer-backdrop {
  background-color: #000 !important;
  opacity: 0.5 !important;
}

.icon-full-size {
  width: 100% !important;
  height: 100% !important;
}

.cursor {
  outline: none;
  cursor: pointer;
}
.hover:hover {
  cursor: pointer;
  background-color: lightgray;
}
.mat-card-content.hover:hover {
  padding: 5px !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.sidebar-item {
  padding: 10px 5px 5px 10px;
}
.sidebar-item.subtitle {
  padding: 0 5px 5px 10px;
}
.sidebar-item-text {
  padding-left: 5px;
  font-size: 12px;
  line-height: 22px;
  /* transition-property: all;
  transition-duration: 50ms; */
  /* transition: font-size 10ms, line-height 10ms; */
  /* transition-delay: 0.1s; */
}
.sidebar-item-text.header {
  font-size: 16px;
}
.sidebar-item-text.subtitle {
  font-size: 11px;
}
.sidebar-item-icon {
  /* padding-left: 5px; */
  font-size: 20px;
  display: inline-flex;
  vertical-align: top;
}
.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.header {
  height: 39px !important;
}

.active.current {
  background-color: rgba(34, 139, 34, 0.9);
  color: #f0f0f0;
}

.pull-right {
  float: right;
}

.errortext {
  font-size: smaller;
  font-weight: 500;
  color: #d32f2f;
  /* padding: 5px; */
  /* border-radius: 5px; */
  /* border: 1px solid #d32f2f; */
  /* margin: 5px; */
}

div.action-fab {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 10;
}
@media screen and (max-width: 820px) {
  div.action-fab {
    right: 20px;
    bottom: 90px;
  }
  .mat-paginator-page-size-label {
    display: none;
  }
  .mat-paginator-page-size,
  .mat-paginator-range-label {
    margin: 0 !important;
  }
}

.wrapper-avatar {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}

.wrapper-avatar img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.project-card {
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
}

/* Popup cards */

.no-data-card-header {
  padding: 20px;
  font-size: 40px;
}
.no-data-card-content {
  padding: 20px;
  font-size: 20px;
}

/* Spinner */

.center-spinner-parent {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.center-spinner-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 150%);
}
.center-spinner-child-text {
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translate(-50%);
  font-size: smaller;
  text-align: center;
}
.overlay-text {
  font-size: smaller;
}
.overlay {
  left: 0 !important;
  top: 0 !important;
  z-index: 10000 !important;
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  /* cursor: pointer !important; */
  visibility: visible !important;
  transition: visibility 0s, opacity 0.4s linear !important;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: auto;
  /* cursor: wait !important; */
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  float: right;
  z-index: 1000;
}

.header-row-button {
  margin: 10px 5px 10px 0 !important;
}

.menu-row-button {
  margin: 5px 5px 5px 5px !important;
}

::ng-deep.mat-menu-panel {
  max-width: none !important;
  padding: 0 10px !important;
  width: 100% !important;
}

/*  ---- Handsontable ---- */

/* Table */
/* .ht_master,
.ht_master tr td {
  background-color: #333;
} */
/* All headers */
/* .handsontable th {
  color: #ccc;
  background-color: #222;
} */

/* Borders */
/* .ht_master tr > td {
  border-bottom: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
} */

/* Edit mode */
/* .handsontableInput {
  background-color: #999 !important;
}
th.ht__highlight {
  background-color: #444 !important;
} */

.nopadding-modal {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* --- Mat Table ---- */

table {
  width: 100%;
}
table.file-table > tr:hover {
  background-color: #363636 !important;
  cursor: pointer;
}
table.file-table,
table.file-table > tr > th,
table.file-table > tr > td {
  border-spacing: 0;
}

tr.mat-row {
  background-color: #333 !important;
}
tr.mat-row.current {
  background-color: #444 !important;
}
tr.mat-row:hover {
  background-color: #363636 !important;
}
.mat-form-field {
  font-size: 12px;
  width: 100%;
}
tr.mat-row {
  height: 45px !important;
}
td.mat-cell {
  font-size: 12px !important;
}
tr.mat-header-row {
  height: 45px !important;
}
.mat-column-columnname {
  text-align: right;
  justify-content: flex-end;
}

/* --- Mat Card ---- */

mat-card {
  background-color: #303030 !important;
}

/* --- Mat Tab ---- */

/* ::ng-deep .mat-tab-labels > .mat-tab-label:first-child {
  display: none;
}
.shownotabs > .mat-tab-labels > .mat-tab-label:first-child {
  display: none;
} */

/* --- Mat Tree ---- */

.tree-invisible {
  display: none;
}

.tree {
  background-color: #303030;
}

.tree ul,
.tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
  background-color: #303030;
}

/*
 * This padding sets alignment of the nested nodes.
 */
.tree .mat-nested-tree-node[aria-expanded="true"] div.mat-tree-node {
  /* padding-left: 30px; */
  background-color: #444;
}
.tree .mat-nested-tree-node[aria-expanded="false"] div[role="group"] {
  /* padding-left: 30px; */
  background-color: #303030;
}
.tree .mat-tree-node:hover {
  background-color: #363636;
}

/*
 * Padding for leaf nodes.
 * Leaf nodes need to have padding so as to align with other non-leaf nodes
 * under the same parent.
 */
.tree div[role="group"] > .mat-tree-node {
  padding-left: 0;
}
.tree .mat-tree-node[role="treeitem"] {
  border-top: solid 4px #505050;
  /* border-bottom: solid 1px #505050; */
}

/* --- mat-button-toggle --- */

mat-button-toggle-group {
  background-color: #353535 !important;
}
.mat-button-toggle-checked {
  background-color: #202020 !important;
}

/* Card */

.card-header-img {
  float: left;
  position: absolute;
}

.card-header-title {
  margin-left: 50px;
}

.card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  /* margin: 1rem; */
  position: relative;
}

.card-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card-1:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.card-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

/* Animations */

.wiggle {
  animation-name: wiggle;
  -webkit-animation-name: wiggle;
  /*-ms-animation-name: wiggle;*/
  animation-duration: 400ms;
  /*-ms-animation-duration: 500ms;*/
  -webkit-animation-duration: 400ms;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  /*-ms-animation-iteration-count: 3;*/
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  /*-ms-animation-timing-function: ease-in-out;*/
}

@-webkit-keyframes wiggle {
  0% {
    transform: rotate(2deg);
  }
  25% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(4deg);
  }
  75% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-ms-keyframes wiggle {
  0% {
    -ms-transform: rotate(2deg);
  }
  25% {
    -ms-transform: rotate(-3deg);
  }
  50% {
    -ms-transform: rotate(4deg);
  }
  75% {
    -ms-transform: rotate(-3deg);
  }
  100% {
    -ms-transform: rotate(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(2deg);
  }
  25% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(4deg);
  }
  75% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}





.dimmed-50 {
  opacity: 50%;
}
.dimmed-20 {
  opacity: 80%;
}
.margin-right-10 {
  margin-right: 10px;
}



/* PrimeNG*/

:host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 5rem !important;
}

.layout-news-active :host ::ng-deep .p-datatable tr > th {
  top: 7rem !important;
}

.p-button-label,
.p-multiselect-label,
.p-inputtext,
.p-element,
.p-multiselect-item,
.p-paginator-current {
  font-size: 11px;
  font-weight: 400;
}
.p-badge {
  font-size: 10px;
  font-weight: 400;
}


.flex-container-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Adjust the height as needed */
}
.fill-space-vertical {
  flex-grow: 1;
  height: 100%
}
/* This class will align content to the top */
.align-top {
  vertical-align: top;
  /*align-self: flex-start;*/
}

/* This class will align content to the bottom */
.align-bottom {
  vertical-align: bottom;
  /*align-self: flex-end;*/
}
